<template>
  <section class="block production-complexes-block">
    <div class="container">
      <div class="block-inner production-complexes-inner">
        <div class="block-content production-complexes-content">
          <ul class="production-complexes">
            <li
              v-for="(complex, idx) in values.items"
              :key="`production-complex-${idx + 1}`"
              class="production-complex"
              @click="() => handleOpenPopup(complex)"
            >
              <div class="production-complex-header">
                <img
                  class="production-complex-header-bg"
                  src="~assets/img/jpg/production-complexes-block/production-complex-title-bg.jpg"
                  :alt="complex.title"
                >
                <p class="production-complex-title">
                  {{ complex.title }}
                </p>
              </div>
              <div class="production-complex-wrapper">
                <div class="left">
                  <div class="production-complex-body">
                    <div
                      v-if="$viewport.isLessThan('md')"
                      class="production-complex-body-slider-wrapper"
                    >
                      <swiper
                        class="production-complex-body-slider"
                        :slides-per-view="1.2"
                        :space-between="10"
                      >
                        <swiper-slide
                          v-for="(image, mainIdx) in complex.images"
                          :key="`production-complex-body-slide-${mainIdx + 1}`"
                          class="production-complex-body-slide"
                        >
                          <nuxt-img
                            loading="lazy"
                            :src="String(image.imageId)"
                            :alt="`production complex ${complex.title} body slide image ${mainIdx + 1}`"
                          />
                        </swiper-slide>
                      </swiper>
                    </div>
                    <div class="production-complex-address-wrapper">
                      <div class="production-complex-geolocation">
                        <img
                          src="~assets/img/svg/production-complexes-block/location.svg"
                          alt="location image"
                        >
                        <p class="value">
                          {{ complex.address }}
                        </p>
                      </div>
                      <div class="production-complex-shipment">
                        <p class="text">
                          Отгрузка:
                        </p>
                        <p class="value">
                          {{ getShipments(idx) }}
                        </p>
                      </div>
                    </div>
                    <div class="production-complex-price">
                      <p class="text">
                        {{ complex.feature_text }} <span
                          v-if="$viewport.isLessThan('lg')"
                          class="value"
                        >
                          {{ complex.feature_value }}
                        </span>
                      </p>
                      <p
                        v-if="$viewport.isGreaterOrEquals('lg')"
                        class="value"
                      >
                        {{ complex.feature_value }}
                      </p>
                    </div>
                    <p
                      v-if="complex.note"
                      class="text production-complex-note"
                      v-html="complex.note"
                    >
                    </p>
                    <div class="production-complex-nomenclature">
                      <p class="text">
                        Номенклатура:
                      </p>
                      <ul class="production-complex-nomenclature-items">
                        <li
                          v-for="(item, nomeclatureIdx) in complex.nomenclatures"
                          :key="`production-complex-nomenclature-item-${nomeclatureIdx + 1}`"
                          class="production-complex-nomenclature-item"
                        >
                          <p class="value">
                            {{ item.nomenclature }}
                          </p>
                        </li>
                      </ul>
                    </div>
                    <div
                      v-if="complex.text"
                      class="text production-complex-text"
                      :class="{'_collapsed': collapsedTextColumnId === idx + 1}"
                      @click="() => handleCollapseText(idx + 1)"

                      v-html="complex.text"
                    ></div>
                    <p
                      v-if="$viewport.isGreaterOrEquals('md')"
                      class="production-complexes-about"
                    >
                      <span>Подробнее</span>
                      <img
                        src="~assets/img/svg/production-complexes-block/arrow-divider-right.svg"
                        alt="arrow divider right icon"
                      >
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <template v-if="isProductionComplexPopupOpen">
      <ProductionComplexModal />
    </template>
  </section>
</template>

<script setup lang="ts">
import { Swiper, SwiperSlide } from 'swiper/vue';
import type { IBlockDefaultProps } from '#sitis/internal/models/common';

const ProductionComplexModal = defineAsyncComponent(() => import('~/components/popups/ProductionComplexModal.vue'));

interface IProductionComplexesBlockImages {
  imageId: number;
  caption: string;
}

interface IProductionComplexesBlockShipment {
  shipment: string;
}

interface IProductionComplexesBlockColumnNomenclature {
  nomenclature: string;
}

export interface IProductionComplexesBlockItem {
  title: string;
  address: string;
  shipments: IProductionComplexesBlockShipment[];
  feature_text: string;
  feature_value: string;
  nomenclatures: IProductionComplexesBlockColumnNomenclature[];
  note?: string;
  text: string;
  images: IProductionComplexesBlockImages[];
}

interface IProductionComplexesBlockValues {
  items: IProductionComplexesBlockItem[];
}

type IProductionComplexesBlockProps = Omit<IBlockDefaultProps, 'values'> & {
  values: IProductionComplexesBlockValues;
};

const $viewport = useViewport();

const props = defineProps<IProductionComplexesBlockProps>();

const {
  isProductionComplexPopupOpen,
  onOpenProductionComplexPopup
} = useProductionComplexPopup();

const collapsedTextColumnId = ref<number>(0);

const getShipments = (idx: number) => props.values.items[idx].shipments.map((item) => item.shipment).join(', ');

const handleCollapseText = (idx: number) => {
  if ($viewport.isLessThan('md')) {
    if (collapsedTextColumnId.value === idx) {
      collapsedTextColumnId.value = 0;
    } else {
      collapsedTextColumnId.value = idx;
    }
  }
};

const handleOpenPopup = (productionComplex: IProductionComplexesBlockItem) => {
  if ($viewport.isGreaterOrEquals('md')) {
    onOpenProductionComplexPopup(productionComplex);
  }
};

</script>

<style lang="scss">
.production-complexes-block {
  .production-complexes {
    box-sizing: border-box;
    display: grid;
    grid-auto-flow: row;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
    position: relative;
  }

  .production-complex {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    background: #EAEAEC;
    overflow: hidden;
    position: relative;
    z-index: 1;
    cursor: pointer;

    &:hover {
      .production-complexes-about {
        color: var(--primary);

        > img {
          filter: var(--primary-filter-img);
        }
      }
    }
  }

  .production-complex-wrapper {
    height: 100%;
    display: grid;
    grid-auto-flow: row;
  }

  .production-complex-header {
    position: relative;
    z-index: 1;
    padding: 30px;
  }

  .production-complex-header-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }

  .production-complex-title {
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 30px;
    text-transform: uppercase;
    color: #FFF;
  }

  .production-complex-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px;

    & .value {
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 25px;
      color: #000;
    }

    & .text {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 25px;
      color: #434343;
    }
  }

  .production-complex-address-wrapper {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }

  .production-complex-geolocation {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  .production-complex-shipment {
    display: flex;
    align-items: flex-start;
    gap: 10px;
  }

  .production-complex-price {
    padding: 15px;
    border-radius: 10px;
    background: var(--main-body-color-bg);
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;

    > .text {
      max-width: 246px;
    }
  }

  .production-complex-nomenclature {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .production-complex-nomenclature-items {
    display: flex;
    flex-wrap: wrap;
    gap: 10px 15px;
  }

  .production-complex-nomenclature-item {
    border-radius: 10px;
    background: #F5F5F5;
    padding: 10px;
  }

  .production-complex-sliders-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .production-complex-main-slider-wrapper {
    width: 100%;
  }

  .production-complex-main-slider {
    width: 100%;
  }

  .production-complex-main-slide {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .production-complex-thumb-slider-wrapper {
    width: 100%;
  }

  .production-complex-thumb-slider {
    width: 100%;
  }

  .production-complex-thumb-slide {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid transparent;
    transition: all .2s linear;

    &.swiper-slide-thumb-active {
      border-color: var(--primary);
    }
  }

  .production-complex-swiper-nav {
    min-width: 42px;
    max-width: 42px;
    height: 42px;
    background: var(--main-body-color-bg);
    border: 2px solid transparent;
    transition: all .2s linear;
    border-radius: 50%;
    overflow: hidden;

    &:hover {
      background: var(--primary);
      border-color: #FFF;
    }

    &-prev {
      left: 27px;
    }

    &-next {
      right: 27px;
    }

    &::after {
      display: none;
    }

    > img {
      width: 100%;
      height: 100%;
      max-width: 42px;
    }
  }

  .production-complexes-about {
    margin-top: auto;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    transition: color .2s linear;

    > img {
      filter: invert(26%) sepia(9%) saturate(443%) hue-rotate(169deg) brightness(97%) contrast(92%);
    }
  }
}

@media (max-width: 1023px) {
  .production-complexes-block {
    .production-complexes {
      gap: 15px;
    }

    .production-complex-wrapper {
      > .right {
        padding: 15px;
        gap: 15px;
      }
    }

    .production-complex-header {
      padding: 15px;
    }

    .production-complex-title {
      font-size: 20px;
    }

    .production-complex-body {
      gap: 15px;
      padding: 15px 0 20px;
    }

    .production-complex-address-wrapper {
      padding: 0 20px;
    }

    .production-complex-price {
      border-radius: 0;
      gap: 10px;

      > .text {
        max-width: 100%;
      }
    }

    .production-complex-note {
      padding: 0 15px;
    }

    .production-complex-nomenclature {
      padding: 0 15px;
    }

    .production-complex-text {
      padding: 0 15px;
    }
  }
}

@media (max-width: 860px) {
  .production-complexes-block {
    .production-complexes {
      grid-template-columns: repeat(1, 1fr);
    }

    .production-complex {
      cursor: default;
    }

    .production-complex-wrapper {
      > .left {
        min-width: 0;
      }
    }

    .production-complex-text {
      max-height: 90px;
      overflow: hidden;
      position: relative;

      &._collapsed {
        max-height: 100%;

        &::after {
          display: none;
        }
      }

      &::after {
        content: 'ЕЩЕ';
        position: absolute;
        text-align: center;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        bottom: 0;
        width: 100%;
        height: 40px;
        left: 50%;
        transform: translate(-50%, 0);
        background: linear-gradient(180deg, transparent, rgb(234 234 236 / 80%) 50%);
      }
    }
  }
}
</style>
