import type { IProductionComplexesBlockItem } from '~/cms/blocks/GrossMainProductionComplexBlock.vue';
import type {Ref} from "vue";

interface IUseProductionComplexPopup{
  isProductionComplexPopupOpen:Ref<boolean>;
  currentProductionComplex:Ref<IProductionComplexesBlockItem|undefined>;
  onOpenProductionComplexPopup:(product:IProductionComplexesBlockItem) => void;
  onCloseProductionComplexPopup:()=>void;
}

export const useProductionComplexPopup = (): IUseProductionComplexPopup => {
  const isProductionComplexPopupOpen = useState<boolean>('isProductionComplexPopupOpen', () => false);
  const currentProductionComplex = useState<IProductionComplexesBlockItem | undefined>('currentProductionComplex', () => undefined);

  const onOpenProductionComplexPopup = (productionComplex: IProductionComplexesBlockItem) => {
    currentProductionComplex.value = productionComplex;
    isProductionComplexPopupOpen.value = true;
  };

  const onCloseProductionComplexPopup = () => {
    isProductionComplexPopupOpen.value = false;
    currentProductionComplex.value = undefined;
  };

  return {
    isProductionComplexPopupOpen,
    currentProductionComplex,
    onOpenProductionComplexPopup,
    onCloseProductionComplexPopup
  };
};
